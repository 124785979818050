<template>
  <div id="divDigitalLiteracyWrapper" class="content-layout-fixer">
    <VueWrapperNarrow>
      <div class="doughnut">
        <div class="doughnut-headline">
          <VueText color="grey-40" sizeLevel="12">{{ contentTypeName }} Tamamlama Oranı</VueText>
        </div>
        <div class="doughnut-wrapper">
          <BrandDoughnutChart
            :key="renderKey"
            :chartData="doughnutDataChart"
            :chartOptions="doughnutOptionsChart"
            class="doughnut-chart"
          />
          <VueText class="mock-text" color="grey-40">%{{ completedPercentage }}</VueText>
        </div>
      </div>
    </VueWrapperNarrow>
    <div class="video-content-holder">
      <VueHeadline level="1">Eğitim Videoları</VueHeadline>
      <div class="video-content-wrapper">
        <div v-for="item in orderedMediaList" :key="item.videoId">
          <BrandMediaListItem
            :text="item.title"
            :percentage="item.percentWatched"
            :mediaSource="item"
            @handleRoute="handleVideoRouting(item)"
          ></BrandMediaListItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandDoughnutChart from '@/components/brand/BrandChart/BrandDoughnutChart.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import BrandMediaListItem from '@/components/brand/BrandMediaListItem/BrandMediaListItem.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesLearn from '@/router/routes/secure/RoutesLearn';
import StorageHelper from '@/utils/storageHelper';
import StorageProps from '@/mixins/storageProps.js';
import { Learn } from '@/services/Api/index';
import vimeoUtils from '@/mixins/vimeoUtils.js';
import gtmUtils from '@/mixins/gtmUtils';

export default {
  name: 'DigitalLiteracy',
  components: {
    BrandDoughnutChart,
    VueText,
    VueHeadline,
    BrandMediaListItem,
    VueWrapperNarrow,
  },
  mixins: [StorageProps, vimeoUtils, gtmUtils],
  data() {
    return {
      contentTypeId: 0,
      contentTypeName: '',
      renderKey: 0,
      completedPercentage: '',
      mediaItems: [],
      doughnutDataChart: {
        labels: ['Tamamlanmış', 'Tamamlanmamış'],
        datasets: [
          {
            label: 'Tamamlama Oranı',
            backgroundColor: ['#50d2c2', '#b4c2d3'],
            data: [],
          },
        ],
      },
      doughnutOptionsChart: {
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
        cutout: '90%',
        responsive: true,
        maintainAspectRatio: true,
        rotation: Math.PI * 300,
        circumference: 270,

        tooltips: {
          mode: 'label',
          backgroundColor: '#ffffff',
          titleFontColor: '#23303d',
          bodyFontColor: '#23303d',
          titleFontStyle: 'normal',
          bodyFontStyle: 'bold',
        },
      },
    };
  },
  computed: {
    orderedMediaList() {
      return this.mediaItems.slice().sort((a, b) => {
        if (a.videoId > b.videoId) {
          return 1;
        } else if (a.videoId < b.videoId) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },

  created() {
    if (this.$route.params && this.$route.params.contentTypeId) {
      Learn.getLearnContentTypes()
        .then(res => {
          if (res.data && res.data.Data) {
            let contentItem = res.data.Data.contentTypes.find(
              x => x.contentTypeId == this.$route.params.contentTypeId,
            );

            if (contentItem) {
              this.contentTypeId = contentItem.contentTypeId;
              this.contentTypeName = contentItem.contentName;
              this.$route.meta.breadcrumb[2].name = contentItem.contentName;
              Learn.getContentByLevelId(this.contentTypeId)
                .then(res => {
                  const response = res.data.Data;
                  if (response) {
                    this.completedPercentage = response.percent.toFixed(2);
                    this.pushDataLayerEvent('learn', {
                      event: 'completed-percentage',
                      category: this.contentTypeName,
                      percent: response.percent.toFixed(2),
                    });
                    this.doughnutDataChart.datasets[0].data[0] = this.completedPercentage;
                    this.doughnutDataChart.datasets[0].data[1] = 100 - this.completedPercentage;
                    this.mediaItems = response.videos
                      .filter(item => item.link && item.link.length > 0)
                      .map(item => ({
                        videoId: item.id,
                        title: item.title,
                        levelId: this.contentTypeId,
                        viewStatus: item.status,
                        percentWatched: item.percent,
                        playbackPosition: item.playbackPosition,
                        isStarting: false,
                        isViewed: item.viewCount > 0 ? true : false,
                        embedVideo: item.link.replace(/\r?\n|\r/g, '').trim(),
                      }));
                  }
                })
                .then(() => {
                  this.renderKey += 1;
                  this.mediaItems = this.getVideoThumbs(this.mediaItems);
                });
            } else {
              this.goToLearn();
            }
          } else {
            this.goToLearn();
          }
        })
        .catch(() => this.goToLearn());
    } else {
      this.goToLearn();
    }
  },
  methods: {
    goToLearn() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}`);
    },
    handleVideoRouting(item) {
      new StorageHelper({
        id: process.env.VUE_APP_STORAGE_VIDEO_DETAIL_KEY,
      }).set(item);
      this.$router.push({
        path: `${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}/${RoutesLearn.VideoDetail.path}`,
        name: 'VideoDetail',
        params: { contentTypeName: this.contentTypeName, contentTypeId: this.contentTypeId },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.doughnut {
  margin: palette-space-level('80') 0 palette-space-level('60');
  border: 1px solid palette-color-level('grey', '20');
  border-radius: palette-radius-level('2');

  &-headline {
    display: flex;
    justify-content: center;
    padding-top: palette-space-level(30);
    padding-bottom: palette-space-level(30);

    svg {
      margin-right: palette-space-level('10');
    }
  }

  &-chart {
    max-width: 220px;
    max-height: 220px;
    margin: auto;
    justify-content: center;
    display: flex;
  }

  &-wrapper {
    position: relative;
    margin-bottom: palette-space-level(20);

    .mock-text {
      position: absolute;
      top: calc(50%);
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: palette-space-level(45);
      font-weight: 300;
      color: #1d1d26;
    }
  }
}

.video-content-holder {
  padding-left: palette-space-level(20);

  .video-content-wrapper {
    margin-top: palette-space-level(20);
    display: grid;
    grid-template-columns: repeat(3, 321px);
    grid-gap: palette-space-level(20);
  }

  .video-content-wrapper > div {
    border: 1px solid palette-color-level('grey', '20');
  }
}
</style>
